import React from 'react';
import { observer } from 'mobx-react';

import Image from '@/components/Image';
import useStore from '@/hooks/useStore';

import Default from '@/images/logo/logo.svg';

import styles from './styles.module.scss';

const HeaderLogo = () => {
  const {
    general: { logo, logoDark },
    siteName,
  } = useStore('globals');

  return (
    <a href="/" className={styles.logo}>
      {logo ? (
        <Image preload loading="eager" {...(logo as ImageObject)} title={siteName} data-testid="imgLogo" />
      ) : (
        <Default />
      )}
      {logoDark ? (
        <Image preload loading="eager" {...(logoDark as ImageObject)} title={siteName} data-testid="imgLogoDark" />
      ) : (
        <Default />
      )}
    </a>
  );
};

export default observer(HeaderLogo);
