import React from 'react';
import cc from 'classcat';
import AnimateHeight from 'react-animate-height';
import { useToggle } from 'react-use';

import Image from '@/components/Image';
import useIsMobile from '@/hooks/useIsMobile';
import useStore from '@/hooks/useStore';
import { IconChevronDown } from '@/images/icons/tabler-icons';
import { getIcon } from '@/lib/IconHelper';
import { urlFor } from '@/lib/Link';

import LinkItem from './LinkItem';

import styles from './styles.module.scss';

interface Props {
  links: ShopCarsLinksFragment[];
  bodies: ShopCarsBodiesFragment[];
}

const ShopCarsDropdown = ({ links, bodies }: Props) => {
  const { isFinanceSite } = useStore('globals');
  const [isOpen, toggleOpen] = useToggle(false);
  const [isMobile] = useIsMobile(768);
  return (
    <div className={cc({ [styles.dd]: true, [styles.isOpen]: isOpen })}>
      <button
        type="button"
        onClick={toggleOpen}
        className={cc({ [styles.ddButton]: true, [styles.ddButtonCars]: true, [styles.isOpen]: isOpen })}
      >
        {isFinanceSite ? ShopCarsDropdown.financeMenuTitle : ShopCarsDropdown.defaultMenuTitle}
        <IconChevronDown />
      </button>
      <div
        className={cc({
          [styles.carsList]: true,
          [styles.hideDivider]: !links.length || !bodies.length,
          [styles.isOpen]: isOpen,
        })}
        role={'list'}
        data-testid="dropdownlistCars"
      >
        <AnimateHeight duration={350} height={isOpen ? 'auto' : 0} className={styles.animationHeight}>
          <div className={cc({ [styles.carsListContainer]: true, [styles.isFinanceSite]: isFinanceSite })}>
            {!!links.length && (
              <div className={styles.carsItems}>
                <p>{isFinanceSite ? ShopCarsDropdown.financeLinkText : ShopCarsDropdown.defaultLinkText}</p>
                {links.map((item, index) => (
                  <LinkItem key={item.uid} item={item} styles={styles} index={index} />
                ))}
              </div>
            )}
            <div className={styles.divider} />
            {!!bodies.length && (
              <div className={styles.carsTypes}>
                <p>{isFinanceSite ? ShopCarsDropdown.financeIconText : ShopCarsDropdown.defaultIconText}</p>
                {bodies.map((item) => (
                  <a
                    data-testid="shopCarsBody"
                    className={cc({
                      [styles.type]: true,
                      [styles.iconItem]: !!item.icon,
                      [styles.labelOnly]: item.logo?.length === 0 && !item.icon,
                    })}
                    href={urlFor(item.linkField?.[0])}
                    key={item.uid}
                  >
                    {item.logo?.[0] && (
                      <span>
                        <Image {...item.logo[0]} />
                      </span>
                    )}
                    {item.icon && getIcon(item.icon) && <span>{getIcon(item.icon)}</span>}
                    {item.title}
                  </a>
                ))}
              </div>
            )}
          </div>
        </AnimateHeight>
      </div>
      {!isMobile && isOpen && (
        <div
          data-testid="dd-cars-backdrop"
          className={cc([styles.backdrop, styles.backdropCars])}
          onClick={toggleOpen}
          onKeyDown={(e) => e.key === 'Escape' && toggleOpen}
          role="presentation"
        />
      )}
    </div>
  );
};

ShopCarsDropdown.defaultMenuTitle = 'Shop Cars';
ShopCarsDropdown.defaultLinkText = 'Shop cars';
ShopCarsDropdown.defaultIconText = 'Browse by body style';
ShopCarsDropdown.financeMenuTitle = 'Finance for you';
ShopCarsDropdown.financeLinkText = 'Get in touch';
ShopCarsDropdown.financeIconText = 'Finance for almost anything';
ShopCarsDropdown.financeIconMobileText = 'Finance your next purchase';

export default ShopCarsDropdown;
