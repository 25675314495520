import { useEffect, useState } from 'react';
import { ScrollDirection, useScrollDirection } from 'react-use-scroll-direction';

export default function useLastScrollDirection() {
  const { scrollDirection } = useScrollDirection();
  const [lastScrollDirection, setLastScrollDirection] = useState<ScrollDirection>(null);

  useEffect(() => {
    if (scrollDirection) setLastScrollDirection(scrollDirection);
  }, [scrollDirection]);

  return lastScrollDirection;
}
