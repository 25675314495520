import React from 'react';
import { observer } from 'mobx-react';

import Button from '@/components/Button';
import useIsMobile from '@/hooks/useIsMobile';
import useStore from '@/hooks/useStore';
import { IconPhone } from '@/images/icons/tabler-icons';

import styles from './styles.module.scss';

const FinanceMenu = () => {
  const { phone } = useStore('globals');
  const [isXlDesktop] = useIsMobile(768, 1024, 1464);

  return (
    <>
      {!!phone && (
        <a data-testid="financePhoneHeader" href={`tel:${phone.replace(/[\D+]/g, '')}`}>
          <IconPhone />
          {!isXlDesktop && <span>{phone}</span>}
        </a>
      )}
      <Button data-testid="financeApplyButton" type="action" href="/finance" styleOverride={styles.button}>
        Apply now
      </Button>
    </>
  );
};
export default observer(FinanceMenu);
