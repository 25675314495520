import React from 'react';

import Button from '@/components/Button';
import { urlFor } from '@/lib/Link';

const enum LinkItemType {
  ShopAllCars = 0,
}

interface LinkItemProps {
  item: ShopCarsLinksFragment;
  styles: {
    readonly [key: string]: string;
  };
  index: LinkItemType;
}

const LinkItem: React.FC<LinkItemProps> = ({ item, styles, index }) => {
  if (index === LinkItemType.ShopAllCars) {
    return (
      <div className={styles.shopAllCarListItem}>
        <Button
          data-testid="shopCarsLink"
          href={urlFor(item.linkField?.[0])}
          key={item.uid}
          type="foreground"
          size="small"
        >
          {item.title}
        </Button>
      </div>
    );
  }

  return (
    <a data-testid="shopCarsLink" className={styles.carsItem} href={urlFor(item.linkField?.[0])} key={item.uid}>
      {item.title}
    </a>
  );
};
export default LinkItem;
