import React from 'react';

import { linkProps, urlFor } from '@/lib/Link';

interface Props {
  item: HeaderLinkFragment;
}

const MenuItem = ({ item }: Props) => {
  const linkField = item.linkField?.[0];
  if (linkField) {
    const props = linkProps(linkField);
    return (
      <a key={item.title} href={urlFor(linkField)} {...props}>
        {item.title}
      </a>
    );
  }

  return null;
};

export default MenuItem;
