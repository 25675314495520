import React from 'react';
import { observer } from 'mobx-react';
import { useRouter } from 'next/router';

import MyFavourites from '@/components/layout/MyFavourites';
import useChatWidget from '@/hooks/useChatWidget';
import useIsMobile from '@/hooks/useIsMobile';
import useStore from '@/hooks/useStore';
import { IconMapPin, IconMessage2, IconPhone } from '@/images/icons/tabler-icons';

const DefaultMenu = () => {
  const {
    general: { locationLinkField, showContactInHeader, showChatInHeader },
    phone,
  } = useStore('globals');
  const [isMobile, isXlDesktop] = useIsMobile(768, 1024, 1464);
  const router = useRouter();
  const chatButtonClick = useChatWidget();

  return (
    <>
      <MyFavourites displayText={isMobile || !isXlDesktop} />
      {locationLinkField && (
        <a
          href={locationLinkField.url ?? '/locations'}
          data-active={router.asPath.startsWith('/locations')}
          aria-label={locationLinkField.text ?? 'Locations'}
        >
          <IconMapPin />
          {!isXlDesktop && <span>{locationLinkField.text ?? 'locations'}</span>}
        </a>
      )}
      {!isMobile && (showContactInHeader || showChatInHeader) && (
        <>
          {!!phone && showContactInHeader && (
            <a data-testid="phoneHeaderButton" href={`tel:${phone.replace(/[\D+]/g, '')}`}>
              <IconPhone />
              {!isXlDesktop && <span>{phone}</span>}
            </a>
          )}
          {showChatInHeader && (
            <button data-testid="messageHeaderButton" onClick={chatButtonClick}>
              <IconMessage2 />
              {!isXlDesktop && <span>Message</span>}
            </button>
          )}
        </>
      )}
    </>
  );
};
export default observer(DefaultMenu);
