import React from 'react';

import Logo from '../Logo';

import styles from './styles.module.scss';

// Simple header only contains logo, used on servicing group sites
export default function SimpleHeader() {
  return (
    <header className={styles.header}>
      <div className={styles.inner}>
        <Logo />
      </div>
    </header>
  );
}
