import React from 'react';
import { observer } from 'mobx-react';

import useIsMobile from '@/hooks/useIsMobile';
import useStore from '@/hooks/useStore';
import { IconMapPin, IconPhone } from '@/images/icons/tabler-icons';

import styles from './styles.module.scss';

const BuyingLocationMenu = () => {
  const {
    phone,
    general: { locationLinkField },
  } = useStore('globals');
  const [isXlDesktop] = useIsMobile(768, 1024, 1464);

  return (
    <>
      {locationLinkField && (
        <a data-testid="buyingLocationHeader" href={locationLinkField.url ?? '/contact-us'}>
          <IconMapPin />
          {!isXlDesktop && <span>Location</span>}
        </a>
      )}
      {phone && (
        <a data-testid="buyingPhoneHeader" className={styles.phone} href={`tel:${phone.replace(/[\D+]/g, '')}`}>
          <IconPhone />
          <span>{phone}</span>
        </a>
      )}
    </>
  );
};
export default observer(BuyingLocationMenu);
