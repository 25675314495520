import React from 'react';
import cc from 'classcat';
import { observer } from 'mobx-react';
import { useRouter } from 'next/router';

import useStore from '@/hooks/useStore';
import { IconHeart } from '@/images/icons/tabler-icons';

import styles from './styles.module.scss';

const MyFavourites = ({ displayText = true }: { displayText?: boolean }) => {
  const router = useRouter();
  const shortlistStore = useStore('shortlistStore');

  return (
    <a
      className={cc({
        [styles.favourites]: true,
        [styles.brand]: true,
      })}
      href="/favourites"
      data-active={router.asPath === '/favourites'}
      aria-label="Favourites"
    >
      <IconHeart />
      {displayText && <span>Favourites</span>}
      {shortlistStore.size > 0 && <p className={styles.count}>{shortlistStore.size}</p>}
    </a>
  );
};
export default observer(MyFavourites);
