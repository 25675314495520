import { useCallback } from 'react';

export default function useChatWidget() {
  return useCallback(() => {
    if (typeof PodiumWebChat !== 'undefined') {
      PodiumWebChat.open();
    }

    if (typeof LCM !== 'undefined') {
      LCM.startLink();
    }
  }, []);
}
