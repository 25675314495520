import React, { useEffect, useState } from 'react';
import cc from 'classcat';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react';
import { useWindowScroll } from 'react-use';

import Navigation from '@/components/layout/Header/Navigation';
import useIsMobile from '@/hooks/useIsMobile';
import useLastScrollDirection from '@/hooks/useLastScrollDirection';
import useStore from '@/hooks/useStore';
import { IconMenu2, IconX } from '@/images/icons/tabler-icons';

import HeaderLogo from '../HeaderLogo';
import SimpleHeader from '../SimpleHeader';
import BuyingLocationMenu from './RightMenu/BuyingLocationMenu';
import DefaultMenu from './RightMenu/DefaultMenu';
import FinanceMenu from './RightMenu/FinanceMenu';

import styles from './styles.module.scss';

interface Props {
  homepageStyle?: boolean;
  favourites?: boolean;
  /** if set, will make set header to stick once user has scroll past number (in px) */
  stickyScrollTop?: number;
  // defaults
  /** sets if header should be sticky when scrolling; defaults true */
  isSticky?: boolean;
  /** sets if header should be full width; defaults false */
  isFullWidth?: boolean;
}

const Header = ({ homepageStyle, favourites, stickyScrollTop, isSticky = true, isFullWidth = false }: Props) => {
  const { siteConfig, isBuyingSite, isFinanceSite } = useStore('globals');
  const { mobileMenuOpen, toggleMobileMenu } = useStore('appState');
  const appState = useStore('appState');
  const [heroScrolled, setHeroScrolled] = useState(false);
  const [opacity, setOpacity] = useState(0);
  const [backgroundColor, setBackgroundColour] = useState(
    !homepageStyle ? 'var(--colour-neutral-white)' : 'rgba(var(--colour-neutral-white), 0)',
  );

  const [isMobile, isTablet] = useIsMobile(768, 1024, 1464);

  const lastScrollDirection = useLastScrollDirection();
  const { y: scrollTop } = useWindowScroll();

  useEffect(() => {
    runInAction(() => {
      if (stickyScrollTop) {
        if (appState.stickyHeaders.header && scrollTop < stickyScrollTop) {
          appState.stickyHeaders.header = null;
        } else {
          switch (lastScrollDirection) {
            case 'UP':
              if (stickyScrollTop && !appState.stickyHeaders.header && scrollTop > stickyScrollTop) {
                appState.stickyHeaders.header = stickyScrollTop;
              }
              break;
            case 'DOWN':
              if (!!appState.stickyHeaders.header && !mobileMenuOpen) {
                appState.stickyHeaders.header = null;
              }
              break;
          }
        }
      } else if (appState.stickyHeaders.header) {
        appState.stickyHeaders.header = null;
      }
    });
  }, [appState.stickyHeaders, lastScrollDirection, mobileMenuOpen, scrollTop, stickyScrollTop]);

  useEffect(() => {
    if (!homepageStyle) {
      return;
    }

    const scrollOpacity = scrollTop > 96 ? 1 : scrollTop / 96;
    if (opacity !== scrollOpacity) {
      setOpacity(scrollOpacity);

      if (homepageStyle) {
        setBackgroundColour(`rgba(var(--colour-neutral-white-in-rgb) / ${scrollOpacity >= 0.75 ? 1 : scrollOpacity})`);
      }
    }

    if (!heroScrolled && scrollOpacity >= 0.75) {
      setHeroScrolled(true);
    } else if (heroScrolled && scrollOpacity < 0.75) {
      setHeroScrolled(false);
    }
  }, [opacity, heroScrolled, scrollTop, homepageStyle]);

  if (siteConfig?.siteGroup === 'servicing') {
    return <SimpleHeader />;
  }

  return (
    <>
      <header
        className={cc({
          [styles.header]: true,
          [styles.homepageStyle]: homepageStyle,
          [styles.heroScrolled]: heroScrolled,
          [styles.favourites]: favourites,
          [styles.hideHeader]: !appState.stickyHeaders.header,
          [styles.sticky]: stickyScrollTop ? scrollTop < stickyScrollTop : isSticky,
          [styles.fullWidth]: isFullWidth,
        })}
        data-active={mobileMenuOpen}
        data-testid="header"
        style={{ backgroundColor }}
      >
        <div className={styles.inner}>
          <div className={styles.left}>
            <HeaderLogo />
            <Navigation
              showDropdown={!isTablet || !isMobile}
              openMobileMenu={mobileMenuOpen}
              onClickAway={toggleMobileMenu}
            />
          </div>
          <div
            className={cc({
              [styles.right]: true,
              [styles.isFinanceSite]: isFinanceSite,
            })}
          >
            {!isBuyingSite && !isFinanceSite && <DefaultMenu />}
            {isBuyingSite && <BuyingLocationMenu />}
            {isFinanceSite && <FinanceMenu />}
            <button
              className={cc([styles.mobileMenu, { [styles.mobileMenuActive]: mobileMenuOpen }])}
              onClick={() => toggleMobileMenu()}
              type="button"
              data-testid="mobileMenuButton"
              data-active={mobileMenuOpen}
              aria-label="open mobile menu"
            >
              {mobileMenuOpen ? <IconX /> : <IconMenu2 />}
            </button>
          </div>
        </div>
      </header>
    </>
  );
};
export default observer(Header);
