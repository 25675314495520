import React, { useEffect, useMemo, useState } from 'react';
import cc from 'classcat';
import { useToggle } from 'react-use';
import { useRouter } from 'next/router';

import useChatWidget from '@/hooks/useChatWidget';
import useIsMobile from '@/hooks/useIsMobile';
import useSiteQuery from '@/hooks/useSiteQuery';
import useStore from '@/hooks/useStore';
import { IconChevronDown, IconMessage2, IconPhone } from '@/images/icons/tabler-icons';
import { extractItems } from '@/lib/GraphQLHelper';
import { urlFor } from '@/lib/Link';

import query from './headerMenu.graphql';
import MenuItem from './menuItem';
import ShopCarsDropdown from './shopCars';

import styles from './styles.module.scss';

type NavigationProps = {
  showDropdown?: boolean;
  openMobileMenu?: boolean;
  onClickAway?: React.MouseEventHandler;
};

const Navigation = ({ showDropdown = true, openMobileMenu = false, onClickAway }: NavigationProps) => {
  const [{ data }] = useSiteQuery<HeaderLinksQueryVariables, HeaderLinksQuery>({ query });
  const allLinks = extractItems<HeaderLinkFragment>(data?.headerLinks) || [] || [];
  const menuItems =
    data?.headerLinks
      ?.filter((p): p is HeaderLinkFragment => !!(p && '__typename' in p))
      .filter((item) => item.parent === null) || [];
  const shopCarsLinks =
    data?.shopCarsLinks?.filter((p): p is ShopCarsLinksFragment => !!(p && '__typename' in p)) || [];
  const shopCarsBodies =
    data?.shopCarsBodies?.filter((p): p is ShopCarsBodiesFragment => !!(p && '__typename' in p)) || [];

  const {
    siteConfig,
    isBuyingSite,
    general: { showContactInHeader, showChatInHeader, headerLinksVisible },
    phone,
  } = useStore('globals');
  const chatButtonClick = useChatWidget();

  const router = useRouter();
  const [isMobile, isTablet, isDesktop] = useIsMobile(768, 1024, 1200);
  const [itemMenuCount, setItemMenuCount] = useState<number>(headerLinksVisible ?? Navigation.overflowIndex);

  const shopCarsVisible = useMemo(() => {
    return siteConfig?.siteGroup !== 'servicing' && shopCarsLinks.length > 0 && shopCarsBodies.length > 0;
  }, [siteConfig?.siteGroup, shopCarsLinks.length, shopCarsBodies.length]);

  //Warning: changing itemCount values will effect test
  useEffect(() => {
    if (showDropdown && !isMobile) {
      let itemCount = headerLinksVisible ?? Navigation.overflowIndex;
      if (isTablet) {
        itemCount = 2;
      } else if (isDesktop) {
        itemCount = 1;
      }
      setItemMenuCount(itemCount);
    } else {
      setItemMenuCount(menuItems.length);
    }
  }, [isMobile, isTablet, isDesktop, showDropdown, menuItems.length, headerLinksVisible]);

  return (
    <React.Fragment>
      <div data-testid="navigation" className={styles.navigation} data-active={openMobileMenu}>
        <div className={styles.inner}>
          {shopCarsVisible && <ShopCarsDropdown links={shopCarsLinks} bodies={shopCarsBodies} />}
          {menuItems.slice(0, itemMenuCount).map((item) => (
            <React.Fragment key={item.uid}>
              {allLinks.filter((c): c is HeaderLinkFragment => c !== null).filter((c) => c.parent?.title === item.title)
                .length > 0 && item.title ? (
                <Dropdown
                  headerTitle={item.title}
                  menuItems={allLinks.filter((c) => c?.parent?.title === item.title)}
                />
              ) : (
                <a
                  data-testid="menuLink"
                  href={urlFor(item.linkField?.[0])}
                  key={item.uid}
                  data-active={router.asPath.includes(urlFor(item.linkField?.[0]))}
                >
                  {item.title}
                </a>
              )}
            </React.Fragment>
          ))}
          {showDropdown && itemMenuCount < menuItems.length && itemMenuCount !== 0 && (
            <Dropdown headerTitle={'More'} menuItems={menuItems.slice(itemMenuCount)} />
          )}
          {isBuyingSite && (
            <div className={styles.contactMobile}>
              <a data-testid="buyingMobileContact" href="/contact-us/ask-question">
                Contact
              </a>
              {phone && (
                <a
                  data-testid="buyingMobilePhone"
                  className={styles.contactItem}
                  href={`tel:${phone.replace(/[\D+]/g, '')}`}
                >
                  <IconPhone />
                  <span>{phone}</span>
                </a>
              )}
            </div>
          )}
          {isMobile && (showContactInHeader || showChatInHeader) && (
            <div className={styles.contactMenu}>
              <a data-testid="menuLink" className={styles.contact} href="/contact-us">
                Contact Us
              </a>
              {showContactInHeader && (
                <a data-testid="phoneMenuButton" className={styles.phone} href={`tel:${phone.replace(/[\D+]/g, '')}`}>
                  <IconPhone />
                  <span>{phone}</span>
                </a>
              )}
              {showChatInHeader && (
                <button data-testid="messageMenuButton" className={styles.message} onClick={chatButtonClick}>
                  <IconMessage2 />
                  <span>Message</span>
                </button>
              )}
            </div>
          )}
        </div>
      </div>
      {openMobileMenu && (
        <div
          data-testid="backdrop"
          className={styles.backdrop}
          onClick={onClickAway && onClickAway}
          onKeyDown={onClickAway ? (e) => e.key === 'Escape' && onClickAway : undefined}
          role="presentation"
        />
      )}
    </React.Fragment>
  );
};

type DropdownProps = {
  headerTitle: string;
  menuItems: HeaderLinkFragment[];
};

const Dropdown = ({ headerTitle, menuItems }: DropdownProps) => {
  const [isOpen, toggleOpen] = useToggle(false);

  return (
    <div className={cc({ [styles.dd]: true, [styles.isOpen]: isOpen })}>
      <button type="button" onClick={toggleOpen} className={cc({ [styles.ddButton]: true, [styles.isOpen]: isOpen })}>
        {headerTitle}
        <IconChevronDown />
      </button>
      {isOpen && (
        <React.Fragment>
          <div className={styles.ddList} role={'list'} data-testid="dropdownlist">
            {menuItems.map((item) => (
              <MenuItem key={item.uid} item={item} />
            ))}
          </div>
          <div
            data-testid="dd-backdrop"
            className={styles.backdrop}
            onClick={toggleOpen}
            onKeyDown={(e) => e.key === 'Escape' && toggleOpen}
            role="presentation"
          />
        </React.Fragment>
      )}
    </div>
  );
};

// Inclusive index at where to cut menuItems into 'more' dropdown.
// will be default at > desktop size, otherwise is set in useEffect
// ignored in mobile or if showDropdown prop = false
Navigation.overflowIndex = 3;

export default Navigation;
